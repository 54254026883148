import React from 'react'
import logo from '../images/logo.png';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
function Navbar() {
  return (
    <div className="header-wrapper navbar-light bg-light">
        <nav className="navbar navbar-expand-lg ">
        <div className="container">
            <a className="navbar-brand" href="#"><img className='img-fluid' src={logo} alt="logo" /></a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse justify-content-center" id="navbarSupportedContent">
            <ul className="navbar-nav mb-2 mb-lg-0">
                <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="/">Home</a>
                </li>
                <li className="nav-item">
                <a className="nav-link" href="#work">Work</a>
                </li>
                <li className="nav-item">
                <a className="nav-link" href="#expertise">Expertise</a>
                </li>
                <li className="nav-item">
                <a className="nav-link" href="#contact">Contact</a>
                </li>
            </ul>
            </div>
            <div className="social-media pl-3 d-none d-sm-block">              
                <div className="d-flex justify-content-end">
                  <div className="facebook"><a href="https://www.facebook.com/Runmydigital/" target="_blank" rel="noopener noreferrer"><FacebookIcon/></a></div>
                  <div className="insta"><a href="https://www.instagram.com/runmydigital/" target="_blank" rel="noopener noreferrer"><InstagramIcon/></a></div>
                  <div className="linkein"><a href="https://www.linkedin.com/company/run-my-digital" target="_blank" rel="noopener noreferrer"><LinkedInIcon/></a></div>
                  <div className="youtube"><a href="https://www.youtube.com/channel/UCdNAVArDuKVhxWMAoYHblLg" target="_blank" rel="noopener noreferrer"><YouTubeIcon/></a></div>
                </div>
              </div>
        </div>
        </nav>
    </div>
  )
}

export default Navbar