import React,{ useRef, useState } from 'react'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import emailjs from '@emailjs/browser';

const Result = () =>{
    return(
        <p className='text-success'>Your message has been successfully sent.</p>
    );
};

function Contact() {
    const form = useRef();
    const [result, showResult] = useState(false);
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_of9u14s', 'template_plrh90l', form.current, 'CwMNLLMmjhaPw4FnB')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
        e.target.reset();
        showResult(true);
    }; 
    setInterval(() => {
        showResult(false);
    }, 5000);
  return (
    <section className="contact-section sec-padd" id='contact'>
            <div className="container">
                <div className="content text-center mb-3">
                    <h2 className="section-title">CONTACT US</h2>
                </div>     
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-wrapper mb-4">
                            <form ref={form} onSubmit={sendEmail}>
                                <div className="mb-3">
                                    <label className="form-label">Your Name (required)</label>
                                    <input type="text" name='user_name' className="form-control" required />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Email address (required)</label>
                                    <input type="email" name='user_email' className="form-control" required />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Company</label>
                                    <input type="text" name='user_company' className="form-control" />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Phone</label>
                                    <input type="number" name='user_phone' className="form-control" />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Subject</label>
                                    <input type="text" name='user_subject' className="form-control" />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Message</label>
                                    <textarea name="user_message" className="form-control" ></textarea>
                                </div>
                                <button type="submit" className="btn btn-primary">Submit</button>
                                <div className="row mt-3">
                                     {result ? <Result/> : null}
                                </div>
                            </form>     
                        </div>     
                    </div>  
                    <div className="col-md-6">
                        <div className="contact-wrapper mb-4">
                            <div className="con-box mb-3">
                                <span className="con-icon"><LocationOnIcon/></span>
                                <span className="con-text">
                                    <strong>Address</strong><br/>
                                    Business point, office #6, Lohana Colony, Paliram Road, Andheri West, Mumbai - 400058     
                                </span>    
                            </div> 
                            <div className="con-box mb-3">
                                <span className="con-icon"><LocalPhoneIcon/></span>
                                <span className="con-text">
                                    <strong>Mobile</strong><br/>
                                    <a href="mailto:8879028513">+91 8879 028 513 </a>   
                                </span>    
                            </div>   
                            <div className="con-box">
                                <span className="con-icon"><MailOutlineIcon/></span>
                                <span className="con-text">
                                    <strong>Email</strong><br/>
                                    <a href="mailto:info@runmydigital.com">info@runmydigital.com</a>  
                                </span>    
                            </div>  
                            <div className="social-media mt-3">
                                <h5>Connect with Us</h5>
                                <div className="d-flex">
                                    <div className="facebook"><a href="https://www.facebook.com/Runmydigital/" target="_blank" rel="noopener noreferrer"><FacebookIcon/></a></div>
                                    <div className="insta"><a href="https://www.instagram.com/runmydigital/" target="_blank" rel="noopener noreferrer"><InstagramIcon/></a></div>
                                    <div className="linkein"><a href="https://www.linkedin.com/company/run-my-digital" target="_blank" rel="noopener noreferrer"><LinkedInIcon/></a></div>
                                    <div className="youtube"><a href="https://www.youtube.com/channel/UCdNAVArDuKVhxWMAoYHblLg" target="_blank" rel="noopener noreferrer"><YouTubeIcon/></a></div>
                                </div>
                            </div>
                        </div> 
                        <div className="map-wrapper">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3769.727779061768!2d72.84178261744384!3d19.119594600000006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c9d10b5f22a3%3A0xdf106c248087a17c!2sBusiness%20Point!5e0!3m2!1sen!2sin!4v1649755474835!5m2!1sen!2sin" width="100%" height="300" loading="lazy"></iframe>  
                        </div>   
                    </div> 
                </div>  
            </div>
    </section>
  )
}

export default Contact