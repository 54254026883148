import React from 'react'

function Footer() {
  return (
    <div className="footer-wrapper">
        <div className="container">
            <div className="copyright text-center py-3 text-white">© Copyright 2022 Run My Digital | Powered by <a className='text-white' target="_blank" href="https://www.vectorart.co/">Vector Art</a></div>
        </div>
    </div>
  )
}

export default Footer